import { createGlobalState } from 'react-hooks-global-state'
import { io } from 'socket.io-client'
import { Topic } from '../models/topic'

const initialState = {
    topics: [] as Topic[],
    roomName: '',
    timerStartTime: 300,
    timerSecondsRemaining: 300,
    countDownFlag: false,
    darkMode: false,
    voting: 'notVoting',
    votingStay: 0,
    votingMoveOn: 0,
    socket: io(process.env.REACT_APP_SOCKET_IO_URL as string, {
        path: '/api/socket.io',
        transports: ['websocket'],
        secure: true,
    }),
    sessionClientID: '',
    votesCast: 0,
    editing: false,
    sortingDisabled: false,
    deleteAllCardsDisabled: false,
    lastStartedTime: new Date(),
    elapsedSeconds: 0,
}

export const { useGlobalState, setGlobalState, getGlobalState } =
    createGlobalState(initialState)

export const resetGlobalState = () => {
    setGlobalState('topics', initialState.topics)
    setGlobalState('timerStartTime', initialState.timerStartTime)
    setGlobalState('timerSecondsRemaining', initialState.timerSecondsRemaining)
    setGlobalState('countDownFlag', initialState.countDownFlag)
    setGlobalState('socket', initialState.socket)
    setGlobalState('roomName', initialState.roomName)
    setGlobalState('darkMode', initialState.darkMode)
    setGlobalState('voting', initialState.voting)
    setGlobalState('votingStay', initialState.votingStay)
    setGlobalState('votingMoveOn', initialState.votingMoveOn)
    setGlobalState('sessionClientID', initialState.sessionClientID)
    setGlobalState('votesCast', initialState.votesCast)
    setGlobalState('editing', initialState.editing)
    setGlobalState('sortingDisabled', initialState.sortingDisabled)
    setGlobalState(
        'deleteAllCardsDisabled',
        initialState.deleteAllCardsDisabled
    )
    setGlobalState('lastStartedTime', initialState.lastStartedTime)
    setGlobalState('elapsedSeconds', initialState.elapsedSeconds)
}
