import { Image, Nav } from 'react-bootstrap'
import './NavBar.scss'
import leanCoffeeLogo from '../../assets/logo.svg'
import React from 'react'
import { useGlobalState } from '../../state/GlobalState'
import { eventTrack } from '../../analytics/analytics-utils'
import ReactTooltip from 'react-tooltip'
import Sun from '../../assets/Sun.svg'
import Moon from '../../assets/Moon.svg'
import FeedbackIcon from '../../assets/LLFeedbackIconFilled.svg'
import CopyLink from '../../assets/LLCopyLinkBlack.svg'
import InfoIcon from '../../assets/LLInfoLogoFilled_1.svg'
import LeanCoffeeOverview from './Modals/LeanCoffeeOverview'
import QrCode from './Modals/QrCode'
import { slide as Menu } from 'react-burger-menu'

function NavBar() {
    const [showLinkCopied, setShowLinkCopied] = React.useState(false)
    const [showLeanCoffeeModal, setShowLeanCoffeeModal] = React.useState(false)
    const [showQRModal, setShowQRModal] = React.useState(false)
    const [roomName] = useGlobalState('roomName')
    const [darkMode, setDarkMode] = useGlobalState('darkMode')
    const link = process.env.REACT_APP_BASE_URL + '/room/' + roomName
    const [sidebarOpenStatus, setSidebarOpenStatus] = React.useState(false)

    const handleSidebarOpenStatus = () => {
        if (sidebarOpenStatus) {
            setSidebarOpenStatus(false)
            document.documentElement.style.overflow = 'initial'
        } else {
            setSidebarOpenStatus(true)
            document.documentElement.style.overflow = 'hidden'
        }
    }

    const shareLeanCoffeeRoom = async () => {
        setShowLinkCopied(true)
        await navigator.clipboard.writeText(link)
        setTimeout(async () => setShowLinkCopied(false), 5000)
    }

    const ifShowLinkCopied = () => {
        return showLinkCopied ? (
            <p data-testid="linkCopied" className={'link-copied'}>
                Link Copied!
            </p>
        ) : (
            <Image
                alt="Link Copy"
                data-testid="link-copy"
                className={'menu-item'}
                src={CopyLink}
            />
        )
    }

    const handleCloseModal = () => {
        setShowLeanCoffeeModal(false)
        setShowQRModal(false)
    }

    function toggleDarkMode() {
        localStorage.setItem('darkMode', String(!darkMode))
        setDarkMode(!darkMode)
    }

    function getDarkModeButton() {
        return (
            <Image
                alt={darkMode ? 'toggle-light-mode' : 'toggle-dark-mode'}
                src={darkMode ? Sun : Moon}
                className={(darkMode ? 'sun' : 'moon') + ' dark-mode-switcher'}
                data-testid="roast-button"
                onClick={() => {
                    eventTrack(
                        'Navigation bar',
                        (darkMode ? 'Light' : 'Dark') + ' Mode Enabled',
                        'Button'
                    )
                    toggleDarkMode()
                }}
            />
        )
    }

    function getMobileBurgerMenu() {
        return (
            <Menu
                isOpen={sidebarOpenStatus}
                onOpen={handleSidebarOpenStatus}
                onClose={handleSidebarOpenStatus}
            >
                <a
                    href="/"
                    id="homeButton"
                    role="menuitem"
                    onClick={() => {
                        eventTrack(
                            'Navigation bar',
                            'Go home using home button',
                            'Button'
                        )
                    }}
                >
                    Home
                </a>
                <span
                    id="infoButton"
                    role="menuitem"
                    onClick={() => {
                        setShowLeanCoffeeModal(true)
                        handleSidebarOpenStatus()
                        eventTrack(
                            'Navigation bar',
                            'Show lean coffee overview',
                            'Button'
                        )
                    }}
                >
                    Information
                </span>
                <span
                    id="qrCode"
                    role="menuitem"
                    onClick={() => {
                        setShowQRModal(true)
                        handleSidebarOpenStatus()
                        eventTrack('Navigation bar', 'Show QR Code', 'Button')
                    }}
                >
                    QR Code
                </span>
                <a
                    id="feedbackButton"
                    role="menuitem"
                    href="https://forms.office.com/r/2DVpwgKdXr"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                        handleSidebarOpenStatus()
                    }}
                >
                    Send Feedback
                </a>
                <span
                    id="copyLink"
                    role="menuitem"
                    onClick={() => {
                        shareLeanCoffeeRoom().then(() =>
                            eventTrack(
                                'Navigation bar',
                                'Share lean coffee room',
                                'Button'
                            )
                        )
                        handleSidebarOpenStatus()
                    }}
                >
                    Copy Link to Room
                </span>
                <span
                    id="toggleTheme"
                    role="menuitem"
                    className="bm-button-border"
                    onClick={() => {
                        eventTrack(
                            'Navigation bar',
                            (darkMode ? 'Light' : 'Dark') + ' Mode Enabled',
                            'Button'
                        )
                        toggleDarkMode()
                        handleSidebarOpenStatus()
                    }}
                >
                    {darkMode ? 'Light Mode' : 'Dark Mode'}
                </span>
            </Menu>
        )
    }

    function getNavSideBar() {
        return (
            <>
                <Nav.Item
                    className="navItem mobile-menu-item"
                    data-tip
                    data-for="info-tip"
                    data-testid="info-tip"
                    onClick={() => {
                        setShowLeanCoffeeModal(true)
                        eventTrack(
                            'Navigation bar',
                            'Show lean coffee overview',
                            'Button'
                        )
                    }}
                >
                    <Image
                        id="info"
                        data-testid="info-icon"
                        src={InfoIcon}
                        alt="info"
                        className="menu-item"
                    />
                    <LeanCoffeeOverview
                        showLeanCoffeeModal={showLeanCoffeeModal}
                        handleCloseModal={handleCloseModal}
                    />
                    <ReactTooltip id="info-tip" place="right" effect="solid">
                        How Lean Latte works
                    </ReactTooltip>
                </Nav.Item>
                <Nav.Item className="navItem">
                    <Nav.Link
                        id="feedback"
                        role="link"
                        aria-label="feedback"
                        href="https://forms.office.com/r/2DVpwgKdXr"
                        target="_blank"
                        className="dark-mode-switcher menu-item mobile-menu-item"
                        data-tip
                        data-for="feedbackTip"
                    >
                        <Image alt="Feedback Icon" src={FeedbackIcon} />
                    </Nav.Link>
                    <ReactTooltip id="feedbackTip" place="right" effect="solid">
                        Send us feedback on the site
                    </ReactTooltip>
                </Nav.Item>

                <Nav.Item
                    className="navItem mobile-menu-item"
                    data-tip
                    data-for="sendRoomLink"
                    data-testid="sendRoomLink"
                    onClick={() => {
                        shareLeanCoffeeRoom().then(() =>
                            eventTrack(
                                'Navigation bar',
                                'Share lean coffee room',
                                'Button'
                            )
                        )
                    }}
                >
                    {ifShowLinkCopied()}
                    <ReactTooltip
                        id="sendRoomLink"
                        place="right"
                        effect="solid"
                    >
                        Copy room link to your clipboard
                    </ReactTooltip>
                </Nav.Item>
                <Nav.Item
                    className="navItem mobile-menu-item"
                    data-tip
                    data-for="darkMode"
                    data-testid="darkMode"
                >
                    {getDarkModeButton()}
                    <ReactTooltip id="darkMode" place="right" effect="solid">
                        Switch between dark and light mode
                    </ReactTooltip>
                </Nav.Item>
            </>
        )
    }

    return (
        <div>
            {getMobileBurgerMenu()}
            <Nav className="flex-column navBar" bsPrefix="navContainer">
                <Nav.Link className="navItem" href="/">
                    <Image
                        id="logo"
                        src={leanCoffeeLogo}
                        alt="Lean Latte Logo"
                        data-tip
                        data-for="home-menu"
                        data-testid="home-menu"
                        onClick={() => {
                            eventTrack(
                                'Navigation bar',
                                'Go home using logo',
                                'Button'
                            )
                        }}
                    />
                    <ReactTooltip id="home-menu" place="right" effect="solid">
                        Navigate to home screen
                    </ReactTooltip>
                </Nav.Link>
                {getNavSideBar()}
            </Nav>
            <QrCode
                showQRModal={showQRModal}
                handleCloseModal={handleCloseModal}
            />
        </div>
    )
}

export default NavBar
