import { setGlobalState, useGlobalState } from '../../state/GlobalState'

const useAddTopic = () => {
    const [socket] = useGlobalState('socket')
    const [roomName] = useGlobalState('roomName')

    const add = (topicName: string) => {
        socket.emit('addTopic', { topicName: topicName, roomName: roomName })
    }

    return [add]
}

const useTopicVote = () => {
    const [socket] = useGlobalState('socket')
    const [roomName] = useGlobalState('roomName')
    const [votesCast] = useGlobalState('votesCast')

    const vote = (topicId: string) => {
        socket.emit('vote', {
            topicId: topicId,
            roomName: roomName,
        })
        setGlobalState('votesCast', votesCast + 1)
    }

    return [vote]
}
const useTopicRemoveVote = () => {
    const [socket] = useGlobalState('socket')
    const [roomName] = useGlobalState('roomName')
    const [votesCast] = useGlobalState('votesCast')

    const vote = (topicId: string) => {
        socket.emit('removeVote', {
            topicId: topicId,
            roomName: roomName,
        })
        setGlobalState('votesCast', votesCast - 1)
    }

    return [vote]
}

const useTopicDelete = () => {
    const [roomName] = useGlobalState('roomName')
    const [socket] = useGlobalState('socket')

    const deleteTopic = (topicId: string) => {
        socket.emit('deleteTopic', { roomName: roomName, topicId: topicId })
    }

    return [deleteTopic]
}

const useSortAllTopics = () => {
    const [roomName] = useGlobalState('roomName')
    const [socket] = useGlobalState('socket')

    const sortAll = () => {
        socket.emit('sortAll', {
            roomName: roomName,
        })
    }

    return [sortAll]
}

const useDeleteAllTopics = () => {
    const [roomName] = useGlobalState('roomName')
    const [socket] = useGlobalState('socket')

    const deleteAll = () => {
        socket.emit('deleteAll', {
            roomName: roomName,
        })
    }

    return [deleteAll]
}

const useUpdateTopic = () => {
    const [roomName] = useGlobalState('roomName')
    const [socket] = useGlobalState('socket')

    const updateTitle = (topicId: string, topicTitle: any) => {
        socket.emit('updateTitle', {
            topicTitle: topicTitle,
            roomName: roomName,
            topicId: topicId,
        })
    }

    const updateIsDone = (topicId: string, isDone: boolean) => {
        socket.emit('updateIsDone', {
            roomName: roomName,
            topicId: topicId,
            isDone: isDone,
        })
    }

    return [updateTitle, updateIsDone]
}

export {
    useTopicVote,
    useTopicRemoveVote,
    useAddTopic,
    useTopicDelete,
    useSortAllTopics,
    useUpdateTopic,
    useDeleteAllTopics,
}
