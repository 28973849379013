import { useGlobalState } from '../../state/GlobalState'

const useVoting = () => {
    const [roomName] = useGlobalState('roomName')
    const [socket] = useGlobalState('socket')

    const submitVote = (vote: string) => {
        socket.emit('voting', { action: vote, roomName: roomName })
    }

    return [submitVote]
}

const useStartEndVoting = () => {
    const [roomName] = useGlobalState('roomName')
    const [socket] = useGlobalState('socket')

    const startEndVoting = (startEnd: string) => {
        if (startEnd.toLowerCase() === 'start') {
            socket.emit('startStopTimer', {
                action: 'Stop',
                roomName: roomName,
            })
        } else {
            socket.emit('resetTimer', {
                initialSeconds: 300,
                roomName: roomName,
            })
        }
        socket.emit('startEndVoting', {
            roomName: roomName,
            action: startEnd,
        })
    }

    return [startEndVoting]
}

export { useVoting, useStartEndVoting }
