import './App.scss'
import React, { useEffect } from 'react'
import Login from './components/Login/Login'
import Home from './components/Home/Home'
import NotFound from './components/404/404'
import ReactGA from 'react-ga4'
import Footer from './components/footer/Footer'
import { useGlobalState } from './state/GlobalState'

const { BrowserRouter, Switch, Route } = require('react-router-dom')
ReactGA.initialize('G-E858798B39', {
    gaOptions: { siteSpeedSampleRate: 100 },
})

ReactGA.pageview(window.location.pathname + window.location.search)

function App() {
    const [darkMode, setDarkMode] = useGlobalState('darkMode')
    useEffect(() => {
        setDarkMode(localStorage.getItem('darkMode') === 'true')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <BrowserRouter forceRefresh={true}>
            <span className={darkMode ? 'dark-mode' : 'light-mode'}>
                <Switch>
                    <Route exact path="/">
                        <Login />
                    </Route>
                    <Route path="/room/:room_name">
                        <div className="App">
                            <Home />
                        </div>
                    </Route>

                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="/404">
                        <NotFound />
                    </Route>
                </Switch>
                <Footer />
            </span>
        </BrowserRouter>
    )
}

export default App
