import React from 'react'
import ConfusedHorse from '../../assets/Confused-Horse.png'
import { useGlobalState } from '../../state/GlobalState'
import { Link } from 'react-router-dom'
import './404.scss'
import { eventTrack } from '../../analytics/analytics-utils'

export default function NotFound() {
    const [roomName] = useGlobalState('roomName')

    return (
        <section className="notFoundImage">
            {roomName ? (
                <h1>Room {roomName} does not exist</h1>
            ) : (
                <h1>Page not found</h1>
            )}
            <img
                className="horseImage"
                src={ConfusedHorse}
                alt="Confused Horse"
            />
            <br />
            <Link to={'/'}>
                <button
                    className="button button404"
                    onClick={() => {
                        eventTrack('404', 'Return To Home', 'Button')
                    }}
                >
                    Return to Home
                </button>
            </Link>
        </section>
    )
}
