import React from 'react'
import './Footer.scss'

export default function Footer() {
    return (
        <footer className={'footer'}>
            <p className={'copyright'}>
                Copyright 2023 | Fueled By{' '}
                <a
                    href="https://artisan.ford.com/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Team Shelby
                </a>{' '}
                | Provide{' '}
                <a
                    href="https://forms.office.com/r/2DVpwgKdXr"
                    target="_blank"
                    rel="noreferrer"
                >
                    Feedback
                </a>
            </p>
        </footer>
    )
}
