import React, { useEffect } from 'react'
import './Home.scss'
import NavBar from '../NavBar/NavBar'
import Topics from '../Topics/Topics'
import ReactGA from 'react-ga4'
import Voting from '../Voting/Voting'

export default function Home() {
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
    }, [])

    return (
        <div>
            <header>
                <a className={'skip-link'} href={'#main-content'}>
                    Skip to main content
                </a>
            </header>
            <main className="grid">
                <NavBar />
                <Voting />
                <Topics />
            </main>
        </div>
    )
}
