import React, { memo, useState } from 'react'
import { useGlobalState } from '../../state/GlobalState'
import { useResetTimer } from '../hooks/TimerHooks'
import './Timer.scss'
import Select from 'react-select'

const ResetTimerButton = memo(() => {
    const [, setCountDownFlag] = useGlobalState('countDownFlag')
    const [resetTimer] = useResetTimer()
    const [resetValue, setResetValue] = useState(null)
    const [darthVaderMode] = useGlobalState('darkMode')
    //const [steam, setSteam] = useState(true)

    const timerResetOptions = [
        { value: 60, label: '1 Minute' },
        { value: 120, label: '2 Minutes' },
        { value: 180, label: '3 Minutes' },
        { value: 240, label: '4 Minutes' },
        { value: 300, label: '5 Minutes' },
    ]

    const colourStyles = {
        control: (styles: any) => ({
            ...styles,
            backgroundColor: 'black',
            border: !darthVaderMode ? 'solid 3px #d97f32' : 'solid 3px #480348',
            fontSize: 'larger',
            boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.5)',
            borderRadius: '10px',
            height: '50px',
            marginTop: '15px',

            '&:hover': {},
        }),
        placeholder: (styles: any) => ({
            ...styles,
            color: 'white',
        }),
        option: (styles: any) => {
            return {
                ...styles,
                fontSize: '20px',
            }
        },
    }

    return (
        <label>
            <Select
                id={'reset-timer-select'}
                data-testid={'reset-timer-select'}
                isSearchable={false}
                classNamePrefix={'reset-timer-select'}
                placeholder="Reset Timer"
                value={resetValue}
                styles={colourStyles}
                onChange={(event) => {
                    if (event?.value !== undefined) {
                        setCountDownFlag(false)
                        resetTimer(event.value)
                        setResetValue(null)
                    }
                }}
                options={timerResetOptions}
            />
        </label>
    )
})

export default ResetTimerButton
