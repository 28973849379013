import { useGlobalState } from '../../state/GlobalState'

const useStartStopTimer = () => {
    const [roomName] = useGlobalState('roomName')
    const [socket] = useGlobalState('socket')

    const startStop = (startStop: string) => {
        socket.emit('startStopTimer', { action: startStop, roomName: roomName })
    }

    return [startStop]
}

const useResetTimer = () => {
    const [roomName] = useGlobalState('roomName')
    const [socket] = useGlobalState('socket')

    const resetTimer = (initialSeconds: number) => {
        socket.emit('resetTimer', {
            initialSeconds: initialSeconds,
            roomName: roomName,
        })
    }

    return [resetTimer]
}

export { useStartStopTimer, useResetTimer }
