import { Modal } from 'react-bootstrap'
import '.././NavBar.scss'
import React from 'react'
import { useGlobalState } from '../../../state/GlobalState'

interface LeanCoffeeProps {
    showLeanCoffeeModal: boolean
    handleCloseModal: () => void
}

function LeanCoffeeOverview({
    showLeanCoffeeModal,
    handleCloseModal,
}: LeanCoffeeProps) {
    const [darkMode] = useGlobalState('darkMode')

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Modal
                data-testid="info-modal"
                show={showLeanCoffeeModal}
                onHide={handleCloseModal}
                dialogClassName={'modal-window'}
                centered
            >
                <span className={darkMode ? 'dark-mode' : 'light-mode'}>
                    <div data-testid="modal-text" className={'info-modal'}>
                        <Modal.Header closeButton className={'info-header'}>
                            <Modal.Title className={'info-title'}>
                                Lean Latte
                            </Modal.Title>
                        </Modal.Header>
                        <div className={'modal-dialogue-description'}>
                            <p>
                                The Lean Latte application allows teams to
                                simply drive a lean coffee conversation. A lean
                                coffee style meeting is an agenda-less meeting
                                in which the participants determine the topics
                                discussed. The purpose of a lean coffee style
                                meeting is to encourage open and honest
                                conversation, alleviate confusion, get questions
                                answered, and get a pulse for what is top of
                                mind for a team.
                            </p>
                        </div>
                        <h3 className={'info-modal-h3'}> How it Works </h3>
                        <div className={'modal-dialogue-tips'}>
                            <ul>
                                <li>
                                    Host introduces a theme for the Lean Coffee
                                    meeting and asks participants to spend a few
                                    minutes entering cards that may include
                                    thoughts, questions, ideas, or concerns
                                    around the theme that they would like to
                                    discuss.
                                </li>
                                <li>
                                    Host spends a few minutes introducing each
                                    card entered, ensuring clarity on cards
                                    entered.
                                </li>
                                <li>
                                    Host asks participants to vote on the items
                                    they would like to discuss. Each participant
                                    has 3 votes and can add or remove votes
                                    using the plus and minus icons on the left
                                    of each topic card.
                                </li>
                                <li>
                                    Host will sort the cards using the sort
                                    button and then starting with cards that
                                    receive the most votes, the host sets a
                                    timer to time-box the discussion.
                                    Discussions are time-boxed in order to keep
                                    topics moving and cover as much ground as
                                    possible.
                                </li>
                                <li>
                                    When the timer ends, participants vote via
                                    the popup window buttons on whether to
                                    continue the discussion or move to the next
                                    card.
                                </li>
                            </ul>
                        </div>
                    </div>
                </span>
            </Modal>
        </div>
    )
}

export default LeanCoffeeOverview
