import { Modal } from 'react-bootstrap'
import '.././NavBar.scss'
import React from 'react'
import { useGlobalState } from '../../../state/GlobalState'
import QRCode from 'qrcode.react'
import useWindowDimensions from '../../hooks/useWindowDimensions'

interface LeanCoffeeProps {
    showQRModal: boolean
    handleCloseModal: () => void
}

function QrCode({ showQRModal, handleCloseModal }: LeanCoffeeProps) {
    const [darkMode] = useGlobalState('darkMode')
    const { height, width } = useWindowDimensions()

    function getSize() {
        if (height === null || width === null) {
            return 128
        }
        if (height > width) {
            return width * 0.73
        } else {
            return height * 0.73
        }
    }

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Modal
                data-testid="qr-modal"
                show={showQRModal}
                onHide={handleCloseModal}
                dialogClassName={'modal-window'}
                centered
            >
                <span className={darkMode ? 'dark-mode' : 'light-mode'}>
                    <div style={{ textAlign: 'center' }} data-testid={'qrcode'}>
                        <h1>Scan To Join Room</h1>
                        <QRCode
                            value={
                                window.location.href
                                    ? window.location.href
                                    : 'https://leanlatte.ford.com/'
                            }
                            size={getSize()}
                        />
                    </div>
                </span>
            </Modal>
        </div>
    )
}

export default QrCode
