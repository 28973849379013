import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import './Checkbox.scss'
type CheckboxData = {
    className: string
    checked: boolean
    'data-testid': string
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}
export default function Checkbox({
    className,
    checked,
    'data-testid': dataTestId,
    onClick,
}: CheckboxData) {
    return (
        <div className={className}>
            <button
                className={`checkbox-button`}
                onClick={onClick}
                data-testid={dataTestId}
                aria-label={'checkbox'}
            >
                <FontAwesomeIcon
                    icon={checked ? faCheck : faCircle}
                    aria-label={checked ? 'checked' : 'unchecked'}
                />
            </button>
        </div>
    )
}
