import React, { useEffect, useState } from 'react'
import { useGlobalState } from '../../state/GlobalState'
import { useStartStopTimer } from '../hooks/TimerHooks'
import mug from '../../assets/Coffee-Mug.png'
import vadersMug from '../../assets/VadersMug.png'
import './Timer.scss'
import { eventTrack } from '../../analytics/analytics-utils'
import { useStartEndVoting } from '../hooks/VotingHooks'
import ResetTimerButton from './ResetTimerButton'

export default function Timer() {
    const [timerSecondsRemaining, setTimerSecondsRemaining] = useGlobalState(
        'timerSecondsRemaining'
    )
    const [countDownFlag] = useGlobalState('countDownFlag')
    const [roomName] = useGlobalState('roomName')
    const [timerStartTime] = useGlobalState('timerStartTime')
    const [lastStartedTime] = useGlobalState('lastStartedTime')
    const [elapsedSeconds] = useGlobalState('elapsedSeconds')
    const [darkMode] = useGlobalState('darkMode')
    const [topics] = useGlobalState('topics')
    const [startStop] = useStartStopTimer()
    const [timerCountdownTimeout, setTimerCountdown] = useState()
    const [timerMillisecondsRemaining, setMillisecondsRemaining] =
        useState(300000)
    const [startEndVote] = useStartEndVoting()
    const [steam, setSteam] = useState(true)

    const canVote = () => {
        return (
            roomName &&
            timerSecondsRemaining <= 0 &&
            topics.filter((topic) => !topic.isDone).length > 1
        )
    }

    const startVote = () => {
        startEndVote('Start')
    }

    useEffect(() => {
        if (countDownFlag) {
            if (canVote()) {
                startVote()
            }
            let timeoutId: any | ReturnType<typeof setTimeout>
            timeoutId = setTimeout(() => {
                let endTime = null
                if (lastStartedTime instanceof Date) {
                    endTime =
                        lastStartedTime.getTime() +
                        timerStartTime * 1000 -
                        elapsedSeconds * 1000
                } else {
                    endTime =
                        new Date(lastStartedTime).getTime() +
                        timerStartTime * 1000 -
                        elapsedSeconds * 1000
                }
                let secondsRemaining = (endTime - new Date().getTime()) / 1000
                if (secondsRemaining > 0) {
                    setMillisecondsRemaining(secondsRemaining * 1000)
                    setTimerSecondsRemaining(Math.floor(secondsRemaining))
                } else {
                    setMillisecondsRemaining(0)
                    setTimerSecondsRemaining(0)
                }
            }, 250)
            timerSecondsRemaining > 0 && setTimerCountdown(timeoutId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timerMillisecondsRemaining, countDownFlag, roomName])

    useEffect(() => {
        if (!countDownFlag) {
            return () => {
                clearTimeout(timerCountdownTimeout)
            }
        }
        return () => {
            if (timerCountdownTimeout) {
                clearTimeout(timerCountdownTimeout)
            }
        }
    }, [countDownFlag, timerCountdownTimeout])

    useEffect(() => {
        let isMounted = true
        setTimeout(() => {
            if (isMounted) setSteam(false)
        }, 50000)
        return () => {
            isMounted = false
        }
    }, [])

    const secondsToCountdownTimer = (seconds: number) => {
        seconds = Number(seconds)
        const m = Math.floor((seconds % 3600) / 60)
        const s = Math.floor((seconds % 3600) % 60)

        const mDisplay = m > 0 ? m + ':' : '0:'
        const sDisplay = s > 0 ? (s > 9 ? s : `0${s}`) : '00'
        return `${mDisplay}${sDisplay}`
    }

    const startStopTimer = () => {
        startStop(getButtonLabel())
    }

    const getButtonLabel = () => {
        if (countDownFlag === false) {
            return 'Start'
        } else {
            return 'Stop'
        }
    }

    const getCoffeeLevel = (timer: number) => {
        return (timer / timerStartTime) * 190
    }

    return (
        <form onSubmit={() => false}>
            {steam ? (
                <>
                    <div data-testid="steam1" className="steam" id="steam1" />
                    <div data-testid="steam2" className="steam" id="steam2" />
                    <div data-testid="steam3" className="steam" id="steam3" />
                    <div data-testid="steam4" className="steam" id="steam4" />
                    <div data-testid="steam5" className="steam" id="steam5" />
                    <div data-testid="steam6" className="steam" id="steam6" />
                </>
            ) : (
                <></>
            )}

            <div className={'cup-container'}>
                {darkMode ? (
                    <img
                        aria-hidden="true"
                        src={vadersMug}
                        className={'cup-image'}
                        alt="coffee cup"
                    />
                ) : (
                    <img
                        aria-hidden="true"
                        src={mug}
                        className={'cup-image'}
                        alt="coffee cup"
                    />
                )}
                <div className={'coffee'}>
                    <div
                        className={'space'}
                        style={{
                            marginBottom: `${getCoffeeLevel(
                                timerSecondsRemaining +
                                    8 * (timerStartTime / 60)
                            )}%`,
                        }}
                    />
                    <div
                        className={'higher-space'}
                        style={{
                            marginBottom: `${getCoffeeLevel(
                                timerSecondsRemaining +
                                    60 +
                                    (timerStartTime / 60) * 50
                            )}%`,
                        }}
                    />
                </div>
                <div
                    className={`timer-face ${
                        timerSecondsRemaining <= 0 ? 'alarm' : ''
                    }`}
                    data-testid={'timer'}
                    role="timer"
                    aria-live="off"
                >
                    {secondsToCountdownTimer(timerSecondsRemaining)}
                </div>
            </div>
            <div className="timer-button-container">
                <div
                    className={`timer-mobile timerPadding ${
                        timerSecondsRemaining <= 0 ? 'alarm' : ''
                    }`}
                    role="timer"
                    aria-live="off"
                >
                    {secondsToCountdownTimer(timerSecondsRemaining)}
                </div>

                <ResetTimerButton />
                <button
                    data-testid={'timer-button'}
                    type={'button'}
                    className={'button timer-buttons startStop'}
                    onClick={() => {
                        startStopTimer()
                        eventTrack(
                            'Timer component',
                            `${getButtonLabel()} timer`,
                            'Button'
                        )
                    }}
                    aria-label={
                        getButtonLabel() === 'Start'
                            ? 'Start timer'
                            : 'Stop timer'
                    }
                >
                    {getButtonLabel()}
                </button>
            </div>
        </form>
    )
}
