import React, { useState } from 'react'
import { Card, Dropdown } from 'react-bootstrap'
import { ReactComponent as CoffeeBean } from '../../assets/coffee-bean.svg'
import './Topics.scss'
import {
    useTopicDelete,
    useTopicRemoveVote,
    useTopicVote,
    useUpdateTopic,
} from '../hooks/TopicHooks'
import './TopicCard.scss'
import { eventTrack } from '../../analytics/analytics-utils'
import { getGlobalState, useGlobalState } from '../../state/GlobalState'
import Checkbox from '../Common/Checkbox'

export default function TopicCard({ topic, id }: { topic: any; id: string }) {
    const [vote] = useTopicVote()
    const [removeVote] = useTopicRemoveVote()
    const [deleteTopic] = useTopicDelete()
    const [update, updateIsDone] = useUpdateTopic()
    const [editMode, setEditMode] = useState(false)
    const [editTopic, setEditTopic] = useState(topic.title)
    const [darkMode] = useGlobalState('darkMode')
    const [editing, setEditing] = useGlobalState('editing')

    function setEditingMode(
        condition: boolean | ((prevState: boolean) => boolean)
    ) {
        setEditing(condition)
        setEditMode(condition)
    }

    function handleSubmit(event: { preventDefault: () => void }) {
        event.preventDefault()
        setEditingMode(false)
        update(topic._id, editTopic)
    }

    const startEditingTopic = async () => {
        setEditingMode(true)
        while (await document.getElementById('titleInput')) {
            setTimeout(() => {}, 100)
        }
        document.getElementById('titleInput')?.focus()
    }

    const cancelEdit = () => {
        setEditingMode(false)
        setEditTopic(topic.title)
    }

    const escFunction = (event: { keyCode: number }) => {
        if (event.keyCode === 27) {
            cancelEdit()
            eventTrack('Room page', 'Cancel edit with esc key', 'Esc key')
        }
    }

    function updateTopicDone() {
        setEditingMode(false)
        updateIsDone(topic._id, !topic.isDone)
    }

    function getTopicText() {
        return (
            <div
                className={
                    topic.isDone
                        ? 'topicTitleSpan disabledText'
                        : 'topicTitleSpan'
                }
                data-testid="titleText"
                onClick={() => {
                    if (!topic.isDone && !editing) {
                        startEditingTopic().then()
                    }
                }}
            >
                {topic.title}
            </div>
        )
    }

    function getVotingControls() {
        return (
            <div className="coffee-button">
                <button
                    data-testid="vote"
                    onClick={() => {
                        vote(topic._id)
                        eventTrack('Room page', 'Upvote topic', 'Button')
                    }}
                    className="coffee-button"
                    disabled={getGlobalState('votesCast') >= 3}
                    aria-live={'polite'}
                    aria-atomic={'true'}
                >
                    +
                </button>
                <CoffeeBean
                    fill={
                        topic.isDone
                            ? '#404040'
                            : !darkMode
                            ? 'rgb(115,99,64)'
                            : 'rgb(246,217,169)'
                    }
                    className="icon"
                />
                <span data-testid="votecount">
                    {topic.votesBySessionID
                        ? topic.votesBySessionID.length
                        : topic.votes}
                </span>
                <button
                    data-testid="removeVote"
                    onClick={() => {
                        removeVote(topic._id)
                        eventTrack('Room page', 'Remove a vote', 'Button')
                    }}
                    className={'coffee-button'}
                    disabled={
                        !topic.votesBySessionID.includes(
                            getGlobalState('sessionClientID')
                        )
                    }
                    aria-live={'polite'}
                    aria-atomic={'true'}
                >
                    -
                </button>
            </div>
        )
    }

    function getMarkDoneControls() {
        return (
            <Checkbox
                checked={topic.isDone}
                className={'mark-complete'}
                data-testid={'doneButton'}
                onClick={() => updateTopicDone()}
            />
        )
    }

    function getTopicControls() {
        return (
            <div className="topicControlGrid">
                {getActionMenu()}
                {getMarkDoneControls()}
            </div>
        )
    }

    function getStandardView() {
        return (
            <div className="cardTopicGrid">
                {getVotingControls()}
                {getTopicText()}
                {getTopicControls()}
            </div>
        )
    }

    function getEditModeView() {
        return (
            <form onSubmit={handleSubmit}>
                <input
                    id="titleInput"
                    data-testid="titleInput"
                    type="text"
                    defaultValue={editTopic}
                    onChange={(event) => setEditTopic(event.target.value)}
                    className="topicInput"
                    onKeyDown={escFunction}
                />
                <div className={'edit-mode-buttons'}>
                    <button
                        data-testid={'saveButton'}
                        className={'save-button'}
                        type={'submit'}
                        aria-label="Save change"
                    >
                        Save
                    </button>
                    &nbsp;
                    <button
                        data-testid={'cancelButton'}
                        className={'cancel-button'}
                        onClick={() => {
                            cancelEdit()
                            eventTrack(
                                'Room page',
                                'Cancel edit with x button',
                                'Button'
                            )
                        }}
                        aria-label="Cancel Topic change"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        )
    }

    function getActionMenu() {
        return (
            <Dropdown className={'topic-actions-menu'}>
                <Dropdown.Toggle
                    className={'dropdown-menu-button'}
                    data-testid={'menuButton'}
                    id={'dropdown-basic' + id}
                >
                    ...
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item
                        data-testid="editButton"
                        onClick={startEditingTopic}
                        disabled={editing}
                    >
                        Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                        data-testid="trashButtonId"
                        onClick={() => {
                            deleteTopic(topic._id)
                            eventTrack('Room page', 'Delete topic', 'Button')
                        }}
                    >
                        Delete
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    return (
        <div className="card-spacing">
            <span data-testid="topic" className="fade-in">
                <Card
                    data-testid="card"
                    className={
                        (topic.isDone ? 'disabledCard' : 'activeCard') +
                        ' ' +
                        (editMode ? 'edit-mode-shadow' : '')
                    }
                >
                    {!editMode ? getStandardView() : getEditModeView()}
                </Card>
            </span>
        </div>
    )
}
