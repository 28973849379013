import React from 'react'
import Modal from 'react-modal'
import { useGlobalState } from '../../state/GlobalState'
import { useStartEndVoting, useVoting } from '../hooks/VotingHooks'
import './Voting.scss'
import { useUpdateTopic } from '../hooks/TopicHooks'
import { eventTrack } from '../../analytics/analytics-utils'

export default function Voting() {
    const [voting, setVoting] = useGlobalState('voting')
    const [votingStay] = useGlobalState('votingStay')
    const [votingMoveOn] = useGlobalState('votingMoveOn')
    const [vote] = useVoting()
    const [startEndVote] = useStartEndVoting()
    const [, updateIsDone] = useUpdateTopic()
    const [topics] = useGlobalState('topics')
    const [darkMode] = useGlobalState('darkMode')

    const voteToStay = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault()
        setVoting('viewingResults')
        vote('stay')
    }

    const voteToMoveOn = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault()
        setVoting('viewingResults')
        vote('moveOn')
    }

    const displayVotingResults = () => {
        if (voting === 'voting') {
            const currentTopic = topics.filter((topic) => !topic.isDone)[0]
            const nextTopic = topics.filter((topic) => !topic.isDone)[1]
            return (
                <form className="votingForm">
                    <div className="votingContainer">
                        <div className="votingChild">
                            <p className="topicLabel">Current topic:</p>
                            <p className="topicText">{currentTopic?.title}</p>
                            <button
                                className="voteButton"
                                onClick={(event) => {
                                    eventTrack('Voting', 'Stay', 'Button')
                                    voteToStay(event)
                                }}
                            >
                                Stay
                            </button>
                        </div>
                        <div className="votingChild">
                            <p className="topicLabel">Next topic:</p>
                            <p className="topicText">{nextTopic?.title}</p>
                            <button
                                className="voteButton"
                                autoFocus={true}
                                onClick={(event) => {
                                    eventTrack('Voting', 'Move On', 'Button')
                                    voteToMoveOn(event)
                                }}
                            >
                                Move On
                            </button>
                        </div>
                    </div>
                </form>
            )
        } else {
            return (
                <form className="votingForm">
                    <div className="votingContainer">
                        <div className="votingChild">
                            <p className="topicLabel">Stay</p>
                            <h3
                                className={
                                    darkMode
                                        ? 'votingResultText darkVotingResultText'
                                        : 'votingResultText'
                                }
                            >
                                {votingStay}
                            </h3>
                        </div>
                        <div className="votingChild">
                            <p className="topicLabel">Move on</p>
                            <h3
                                className={
                                    darkMode
                                        ? 'votingResultText darkVotingResultText'
                                        : 'votingResultText'
                                }
                            >
                                {votingMoveOn}
                            </h3>
                        </div>
                    </div>
                    <button
                        className="endVotingButton"
                        onClick={(event) => {
                            eventTrack('Voting', 'End Voting', 'Button')
                            endVoting(event)
                        }}
                    >
                        End Voting For All
                    </button>
                </form>
            )
        }
    }

    const endVoting = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault()
        const currentTopic = topics.filter((topic) => !topic.isDone)[0]
        if (votingMoveOn > votingStay) {
            updateIsDone(currentTopic._id, true)
        }
        startEndVote('end')
    }

    return (
        <Modal
            isOpen={voting === 'voting' || voting === 'viewingResults'}
            className={
                darkMode
                    ? 'dark-mode votingModal darkModal'
                    : 'light-mode votingModal'
            }
            ariaHideApp={false}
            contentLabel="Voting Modal"
            overlayClassName="overlay"
        >
            <span>
                <h2 className="modalTitle">
                    {voting === 'voting' ? "Time's Up!" : 'Voting Results'}
                </h2>
                {displayVotingResults()}
            </span>
        </Modal>
    )
}
