import React, { useEffect } from 'react'
import './Topics.scss'
import FlipMove from 'react-flip-move'
import TopicCard from './TopicCard'
import AddTopic from './AddTopic'
import { useGlobalState } from '../../state/GlobalState'
import Timer from '../Timer/Timer'
import { useFetchRoom } from '../hooks/RoomHooks'
import useSocket from '../../state/socketHandler'
import { useHistory, useParams } from 'react-router-dom'
import vader from '../../assets/DarthVader.png'
import { Image } from 'react-bootstrap'
import TopicControls from './TopicControls'
import TopicVotingCounter from '../TopicVotingCounter/TopicVotingCounter'
import { Room } from '../../models/room'
type TopicsParams = {
    room_name: string
}
export default function Topics() {
    const [topics, setTopics] = useGlobalState('topics')
    const [, setTimerStartTime] = useGlobalState('timerStartTime')
    const [, setTimerSecondsRemaining] = useGlobalState('timerSecondsRemaining')
    const [, setTimerLastStartedTime] = useGlobalState('lastStartedTime')
    const [, setTimerElapsedSeconds] = useGlobalState('elapsedSeconds')
    const [, setSortingDisabled] = useGlobalState('sortingDisabled')
    const [, setDeleteAllCardsDisabled] = useGlobalState(
        'deleteAllCardsDisabled'
    )
    const [, setCountdownFlag] = useGlobalState('countDownFlag')
    const [, setVoting] = useGlobalState('voting')
    const [, setVotingStay] = useGlobalState('votingStay')
    const [, setVotingMoveOn] = useGlobalState('votingMoveOn')
    const [roomName, setRoomName] = useGlobalState('roomName')
    const params = useParams<TopicsParams>()
    const [requestedRoom] = useFetchRoom()
    useSocket()
    let history = useHistory()

    function setGlobalStates(data: Room) {
        if (data.timerState.lastStartedTime === undefined) {
            setTimerLastStartedTime(new Date())
        } else {
            setTimerLastStartedTime(data.timerState.lastStartedTime)
        }
        setTopics(
            data.topics.map((topic) => {
                if (topic.votesBySessionID === undefined) {
                    return { ...topic, votesBySessionID: [] }
                }
                return topic
            })
        )
        setTimerStartTime(data.timerState.initialSeconds)
        setTimerSecondsRemaining(data.timerState.secondsRemaining)
        //setTimerLastStartedTime(data.timerState.lastStartedTime)
        setTimerElapsedSeconds(data.timerState.elapsedSeconds)
        setSortingDisabled(
            data.timerState.initialSeconds !==
                data.timerState.secondsRemaining || data.topics.length === 0
        )
        setDeleteAllCardsDisabled(data.topics.length === 0)
        setCountdownFlag(data.timerState.startStopState === 'STARTED')
        setVoting(data.votingState.voting ? 'voting' : 'notVoting')
        setVotingStay(data.votingState.stay)
        setVotingMoveOn(data.votingState.moveOn)
    }

    useEffect(() => {
        if (roomName === '') {
            setRoomName(params.room_name)
        }
        requestedRoom.data && setGlobalStates(requestedRoom.data)
        if (requestedRoom.error) {
            history.push('/404')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.room_name, requestedRoom.data, requestedRoom.error])

    return (
        <div className="card-topics" data-testid="Topics">
            <Image src={vader} className="hidden-in-smallness" />
            <div
                className="topics-container"
                style={{ background: 'url(vader)' }}
            >
                <div className="topics-column">
                    <div className="flex-topics">
                        <h2 className="header-card">Lean Latte</h2>
                        <AddTopic />
                        <div
                            data-testid="topic-count"
                            className="line-with-text"
                            aria-live={'polite'}
                            aria-atomic={'true'}
                        >
                            Topics Remaining:{' '}
                            {topics.filter((topic) => !topic.isDone).length}
                        </div>

                        <div className="topics">
                            <FlipMove duration={737}>
                                {topics
                                    .filter((topic) => !topic.isDone)
                                    .map((topic, index) => {
                                        return (
                                            <div
                                                data-testid={
                                                    index === 0
                                                        ? 'currentTopic'
                                                        : ''
                                                }
                                                className={
                                                    index === 0
                                                        ? 'current-topic'
                                                        : ''
                                                }
                                                key={topic._id}
                                            >
                                                <TopicCard
                                                    topic={topic}
                                                    id={topic._id}
                                                />
                                            </div>
                                        )
                                    })}
                            </FlipMove>
                        </div>
                        <div className="topics">
                            <FlipMove duration={737}>
                                {topics
                                    .filter((topic) => topic.isDone)
                                    .map((topic) => {
                                        return (
                                            <div key={topic._id}>
                                                <TopicCard
                                                    topic={topic}
                                                    id={topic._id}
                                                />
                                            </div>
                                        )
                                    })}
                            </FlipMove>
                        </div>
                    </div>
                </div>
                <div className="timer-area">
                    <Timer />
                    <TopicVotingCounter />
                </div>
                <div className="topic-controls-column">
                    <TopicControls />
                </div>
            </div>
        </div>
    )
}
