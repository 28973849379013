import React, { useState } from 'react'
import { useAddTopic } from '../hooks/TopicHooks'
import { eventTrack } from '../../analytics/analytics-utils'

export default function AddTopic() {
    const [newTopic, setNewTopic] = useState('')
    const [add] = useAddTopic()

    const submitNewTopic = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        add(newTopic)
        eventTrack('Room page', 'Add new topic', 'Text field')
        setNewTopic('')
    }
    return (
        <form data-testid="add-topic" onSubmit={submitNewTopic}>
            <input
                className="add-topic-textbox"
                placeholder="Add a topic..."
                value={newTopic}
                onChange={(e) => setNewTopic(e.target.value)}
                aria-label="Add a topic"
            />
        </form>
    )
}
