import { getGlobalState } from '../../state/GlobalState'
import '../Topics/TopicControls.scss'

export default function TopicVotingCounter() {
    const userVotes = getGlobalState('votesCast')
    return (
        <div className="voteRemainingText">{`Votes Remaining: ${
            3 - userVotes
        }`}</div>
    )
}
